import analytics from './analytics.js';
import initLinkdedIn from './linkedin.js';
import geolocation from './user_location';

const PERFORMANCE = 'C0002',
  FUNCTIONAL = 'C0003',
  TARGETING = 'C0004',
  SOCIAL = 'C0005';

const ONETRUST_SCAN = /cookie-scan/i.test(window.location.search);

class GDPR {
  constructor() {
    this.listeners = [];
    if (window.pageData && window.pageData.statusCode && window.pageData.statusCode === 404) {
      // Turn off gdpr and tracking for 404 pages.
      return;
    }

    // Define global OneTrust callback function for when cookie prefs change.
    window.OptanonWrapper = () => {
      // A small timeout is needed to give OneTrust a moment to set cookies on initial
      // page load for non EU markets.
      setTimeout(() => {
        this.listeners.forEach((callback) => {
          callback();
        });
      }, 0);
    };

    if (!/x-craft-live-preview/.test(window.location.search)) {
      geolocation.location.then(this.initOneTrust).catch(() => {
        this.initOneTrust();
      });
    }
  }

  /**
   * Init onetrust once we have user geolocation
   * @param {Object} geo Geo Data
   */
  initOneTrust(geo) {
    if (geo && geo.data && geo.data.countryCode) {
      let countryCode = geo.data.countryCode;

      // Use our own geolocation
      window.OneTrust = {
        geolocationResponse: {
          countryCode: countryCode
        }
      };
    }

    let el = document.createElement('script');
    el.charset = 'UTF-8';
    el.async = true;
    el.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';

    let scriptId = document.body.getAttribute('data-onetrust-id');
    if (window.location.host !== 'www.vml.com') {
      scriptId += '-test';
    }

    el.setAttribute('data-domain-script', scriptId);
    document.head.appendChild(el);
  }

  enableHubspotForms() {
    let placeholders = document.querySelectorAll('.gdpr-placeholder.hubspot-form');
    if (!placeholders.length) {
      return;
    }

    let el = document.createElement('script');
    el.charset = 'UTF-8';
    el.async = true;
    el.defer = true;
    el.src = 'https://js.hsforms.net/forms/v2.js';
    el.onload = () => {
      for (let i = 0, len = placeholders.length; i < len; i++) {
        let placeholder = placeholders[i];
        let section = placeholder.parentElement;
        let id = 'hbspt-' + i;
        section.id = id;

        let formId = placeholder.dataset.id;
        if (formId) {
          let portalId = '3307539';

          if (/^http/.test(formId)) {
            // Pull out portal and form ID from URLs
            // https://app.hubspot.com/forms/3307539/1abb3986-e568-4b17-84cb-20342a4b87e7
            // https://app.hubspot.com/forms/3307539/1abb3986-e568-4b17-84cb-20342a4b87e7/performance
            // https://app.hubspot.com/forms/3307539/editor/1abb3986-e568-4b17-84cb-20342a4b87e7/edit/form
            let match = /forms\/([^/]+)\/(?:editor\/)?([^/]+)/.exec(formId);
            portalId = match[1];
            formId = match[2];
          }

          if (formId)
            // This actually can be tweaked to have overrides for: redirectUrl or inlineMessage.
            // Food for thought.
            // https://legacydocs.hubspot.com/docs/methods/forms/advanced_form_options
            window.hbspt.forms.create({
              target: `#${id}`,
              region: 'na1',
              portalId: portalId,
              formId: formId
            });
        }
        section.removeChild(placeholder);
      }
    };
    document.head.appendChild(el);
  }

  /**
   * Register a callback for cookie pref changes.
   * @param {Function} callback Function
   */
  onChange(callback) {
    this.listeners.push(callback);
  }

  /**
   * Check if the user has accepted performance cookies.
   * @return {Boolean}
   */
  arePerformanceAllowed() {
    let groups = this.getCookieGroups();
    return ONETRUST_SCAN || (groups && groups[PERFORMANCE]);
  }

  /**
   * Check if the user has accepted functional cookies.
   * @return {Boolean}
   */
  areFunctionalAllowed() {
    let groups = this.getCookieGroups();
    return ONETRUST_SCAN || (groups && groups[FUNCTIONAL]);
  }

  /**
   * Check if the user has accepted targeting cookies.
   * @return {Boolean}
   */
  areTargetingAllowed() {
    let groups = this.getCookieGroups();
    return ONETRUST_SCAN || (groups && groups[TARGETING]);
  }

  /**
   * Gets the groups information from OptanonConsent as a sting and returns a groups object.
   * @return {Object} The groups object, null if the no groups are set.
   */
  getCookieGroups() {
    let optCookie = this.getCookie('OptanonConsent');
    if (!optCookie || !optCookie.groups) {
      return null;
    } else {
      let pairs = optCookie.groups.split(','),
        obj = {};

      pairs.forEach(function(pair) {
        let kv = pair.split(':');
        obj[kv[0]] = parseInt(kv[1]) > 0;
      });

      return obj;
    }
  }

  /**
   * Gets the cookie by name as an object
   * @param {String} name The name of the cookie.
   * @param {Boolean} True if we are extracting a single value cookie value.
   * False by default for OneTrust complex cookies.
   * @return {Object} The cookie object.
   */
  getCookie(name, singleValue = false) {
    let match = document.cookie.match(new RegExp(name + '=([^;]+)'));
    if (singleValue) {
      return match ? match[1] : null;
    }

    if (match) {
      let hashes = decodeURI(match[1]).split('&'),
        obj = {};

      hashes.forEach(function(hash) {
        let kv = hash.split('=');
        obj[kv[0]] = decodeURIComponent(kv[1]);
      });
      return obj;
    }
  }

  /**
   * Delete a cookie.
   * @param {String} name The name of the cookie.
   */
  deleteCookie(name) {
    // Delete a cookie without and then with both base and www. sub-domain.
    // Cookie deletion is annoying :(
    let domains = [window.location.host];
    if (/^www\./.test(window.location.host)) {
      domains.push(window.location.host.substring(4));
    }
    document.cookie = `${name}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    domains.forEach((domain) => {
      document.cookie = `${name}=; path=/; domain=${domain}; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    });
  }

  /**
   * Replace .gdpr-placeholder with iframes.
   * @param {Element} scope The parent element to look for embeds in.
   * Can be null to have all emebds found.
   */
  enableEmbeds(scope) {
    scope = scope || document;
    let divs = scope.querySelectorAll('.gdpr-placeholder.iframe');
    divs.forEach((div) => {
      let iframe = document.createElement('iframe'),
        attrs = Object.assign({}, div.dataset);
      div.classList.forEach((c) => {
        if (!['gdpr-placeholder', 'iframe'].includes(c)) {
          iframe.classList.add(c);
        }
      });
      Object.keys(attrs).forEach((key) => {
        iframe.setAttribute(key, attrs[key]);
      });
      div.parentNode.replaceChild(iframe, div);
    });
  }

  /**
   * Enable SF tracking
   */
  enableSalesforce() {
    window._etmc.push(['trackPageView']);
  }

  /**
   * Stop SF tracking.
   * https://help.salesforce.com/s/articleView?id=sf.mc_ctc_do_not_track.htm&type=5
   */
  stopSalesforceTracking() {
    window._etmc.push(['doNotTrack']);
  }
}

// Create singleton
const gdpr = new GDPR();

// Setup default gdpr listener for Google Analytics and 6sense
let hasFunctional = gdpr.areFunctionalAllowed();
let hasTargeting = gdpr.areTargetingAllowed();

if (hasFunctional) {
  // Avoid flicker of gray gdpr placeholder that goes away immediately.
  document.querySelectorAll('.gdpr-placeholder').forEach((el) => el.classList.add('transparent'));
}

gdpr.onChange(() => {
  let allowFunctional = gdpr.areFunctionalAllowed();
  let allowTargeting = gdpr.areTargetingAllowed();

  let gaUserData = {};
  gaUserData.is_internal_user = gdpr.getCookie('wt_int', true);

  // Initialize or re-initialize google analytics depending on the user's cookie preferences.
  // We will only persist GA cookies when functional cookies are allowed.
  geolocation.location
    .then((location) => {
      gaUserData = {
        ...window.pageData.analyticsData,
        ...gaUserData
      };

      analytics.createGA4(allowTargeting, Object.keys(gaUserData).length > 0 ? gaUserData : null);
    })
    .catch(() => {
      analytics.createGA4(allowTargeting, Object.keys(gaUserData).length > 0 ? gaUserData : null);
    });

  if (allowFunctional) {
    hasFunctional = true;

    gdpr.enableEmbeds();
    gdpr.enableHubspotForms();
  }

  if (allowTargeting) {
    hasTargeting = true;
    initLinkdedIn();
    gdpr.enableSalesforce();
  }

  let reload = false;

  // User turned off targeting.
  if (hasTargeting && !allowTargeting) {
    if (window.appData.ga4Id) {
      // https://support.google.com/analytics/answer/11397207?hl=en
      gdpr.deleteCookie('_ga_' + window.appData.ga4Id.replace(/^G-/i, ''));
    }
  }

  // User turned off functional and we have functional scripts loaded, so refresh to disable it.
  if (hasFunctional && !allowFunctional) {
    // Delete GA cookies
    // TODO: make sure this is still needed.
    gdpr.deleteCookie('_ga');
    gdpr.deleteCookie('_gat');
    gdpr.deleteCookie('_gid');

    // Delete Hubspot cookies
    gdpr.deleteCookie('hubspotutk');
    gdpr.deleteCookie('__hssc');
    gdpr.deleteCookie('__hssrc');
    gdpr.deleteCookie('__hstc');

    // Stop SF tracking
    gdpr.stopSalesforceTracking();

    reload = true;
  }

  if (reload) {
    window.location.reload();
  }
});

export default gdpr;
