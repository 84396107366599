import EntryFilter from '../components/entry-filter';

export default {
  components: { EntryFilter },
  data: function() {
    return {
      loading: false,
      hasMore: false,
      showOther: true
    };
  },

  mounted() {
    if (this.$refs.hasMore) {
      this.hasMore = true;
    }

    this.checkForOther();
  },

  methods: {
    /**
     * Add/replace cards.
     * @param {String} html The html to add.
     * @param {Boolean} append True if we're adding rather than replacing.
     * @param {Boolean} hasMore Indicates there is more cards to load.
     */
    addCards(html, append, hasMore) {
      this.hasMore = hasMore;

      // If replacing cards, remove previous ones.
      if (!append) {
        let children = [];
        for (let i = 0, len = this.$refs.cards.children.length; i < len; i++) {
          if (!this.$refs.cards.children[i].matches('.loading-mask')) {
            children.push(this.$refs.cards.children[i]);
          }
        }

        children.forEach((el) => {
          this.$refs.cards.removeChild(el);
        });
      }

      if (html) {
        let existingVertical = this.$refs.cards.querySelector('.vertical-cards');
        if (existingVertical) {
          let el = document.createElement('div');
          el.innerHTML = html;
          el.querySelectorAll('.card').forEach((card) => {
            existingVertical.appendChild(card);
          });
        } else {
          this.$refs.cards.insertAdjacentHTML('beforeend', html);
        }
        this.showOther = false;
      }
    },

    /**
     * If we have less than 2 entries, show other entries.
     */
    checkForOther() {
      if (!this.$refs.cards) {
        this.showOther = true;
        return;
      }
      let cards = this.$refs.cards.querySelectorAll('.card');
      this.showOther = cards.length <= 2;
    }
  }
};
