import Helpers from './util/helpers.js';
import { debounce } from 'debounce';
import { tns } from 'tiny-slider/src/tiny-slider';
import Component from './util/component';

class Headline extends Component {
  static SLIDE_DURATION = 7000;
  static DEFAULT_COLOR = '#f4f4f4';
  static DEFAULT_BRIGHTNESS = 'dark';

  /**
   * Create a Headline.
   */
  constructor() {
    super(document.querySelector('section.headline'));

    let sliderEl = this.el.querySelector('.headline-slider');
    this.isSlider = sliderEl && sliderEl.children.length > 1;

    this.color = Headline.DEFAULT_COLOR;
    this.brightness = Headline.DEFAULT_BRIGHTNESS;

    if (this.isSlider) {
      let opt = {
        container: sliderEl,
        controlsContainer: sliderEl.parentElement.querySelector('.controls'),
        arrowKeys: true,
        nav: false,
        mouseDrag: true,
        preventScrollOnTouch: 'auto',
        autoplay: false,
        autoplayButtonOutput: false,
        lazyload: this.isSlider,
        speed: 600,
        responsive: {
          599: {
            controls: false
          },
          600: {
            controls: true
          }
        }
      };
      this.slider = tns(opt);
      let bullets = sliderEl.querySelectorAll('.bullets .bullet');

      for (let i = 0; i < bullets.length; i++) {
        bullets[i].style.animationDuration = Headline.SLIDE_DURATION + 'ms';
      }

      let content = sliderEl.querySelectorAll('.slide .content'),
        tabbable = [];

      content.forEach((slide, i) => {
        let links = slide.querySelectorAll('a, button');

        if (i !== 1) {
          Helpers.disableTabbing(links);
        }

        tabbable.push(links);
      });

      let keyboardUser = false;
      let changeSlide = () => {
        if (!keyboardUser && this.slider && this.slider.goTo) {
          this.slider.goTo('next');
        }
      };
      let sliderTimer = setTimeout(changeSlide, Headline.SLIDE_DURATION);

      // Stop autoslide on carousel. User will manually change slides.
      let keyPress = (event) => {
        if (event.keyCode === 9 || event.keyCode === 13) {
          keyboardUser = true;
          clearTimeout(sliderTimer);
          window.removeEventListener('keyup', keyPress);
        }
      };

      window.addEventListener('keyup', keyPress);

      this.slider.events.on('indexChanged', (info) => {
        clearTimeout(sliderTimer);
        sliderTimer = setTimeout(changeSlide, Headline.SLIDE_DURATION);
        // Every slides contain bullets and the slide makes copies of the first and last slide.
        // We have to do some math to get the correct bullet to animate.
        // Index is the actual index while display index starts at 1 and shows actual slide number.
        let bulletIndex = info.index * info.slideCount + info.displayIndex - 1;
        for (let i = 0; i < bullets.length; i++) {
          if (i === bulletIndex) {
            bullets[i].classList.add('active');
          } else {
            bullets[i].classList.remove('active');
          }
        }

        Helpers.disableTabbing(tabbable[info.indexCached]);
        Helpers.enableTabbing(tabbable[info.index]);

        // Update the headline brightness when the slide changes.
        this.updateColorAndBrightness(sliderEl.children[info.index]);
      });

      if (opt.controlsContainer) {
        // preventing entire container from being selected during keyboard tab through
        opt.controlsContainer.setAttribute('tabindex', -1);
        let buttons = opt.controlsContainer.children;
        if (buttons.length !== 2) {
          // Something went wrong.
          return;
        }

        // Setting gallery/carousel arrows to be focusable on tab
        buttons[0].setAttribute('tabindex', 0);
        buttons[1].setAttribute('tabindex', 0);
      }

      // Update the headline brightness
      this.updateColorAndBrightness(sliderEl.firstElementChild);

      this.el.addEventListener('click', (e) => {
        if (e.target.classList.contains('bullet') && !isNaN(e.target.dataset.slide)) {
          this.slider.goTo(e.target.dataset.slide);
        }
      });
    } else if (!this.el.querySelector('.default-image')) {
      this.image = this.el.querySelector('.main-image');

      this.updateColorAndBrightness(sliderEl.firstElementChild);
    } else {
      this.el.style.backgroundColor = this.getColor();
    }

    // On mobile devices (where the headline is full height) make the headline
    // the size of the viewport to compensate for the nav bars
    if (Helpers.isUserMobile() && sliderEl) {
      // delay common call until everything is initialized.
      setTimeout(() => {
        sliderEl.style.height =
          Helpers.getViewportSize().height - window.$common.navigation.getHeight() + 'px';
      }, 0);
    }
  }

  /**
   * Returns the color of the site.
   * @return {string} The hex site color.
   */
  getColor() {
    return this.color;
  }

  /**
   * Returns the color brightness of the site color.
   * @return {string} The color brightness 'dark' or 'light'
   */
  getBrightness() {
    return this.brightness;
  }

  /**
   * Update the section brightness (class: dark/light) based on the side content.
   * @param element
   */
  updateColorAndBrightness(element) {
    if (!element) {
      return;
    }

    this.color = element.dataset.color;
    this.brightness = element.dataset.brightness;

    this.el.classList.remove('light');
    this.el.classList.remove('dark');
    this.el.classList.add(this.brightness);
  }

  destroy() {
    if (this.slider) {
      this.slider.destroy();
    }
  }
}

export default Headline;
