import Common from '../_common';
import Helpers from '../util/helpers.js';

export default {
  data: function() {
    return {
      videoModal: {
        isOpen: false,
        player: null,
        videoId: null,
        playLink: null
      }
    };
  },

  methods: {
    /**
     * Open the video modal.
     * @param {Number} id The id of the video.
     */
    openVideoModal(id) {
      let modalVideo = this.$refs['modal-video'],
        buttonHolder = this.$refs[`video-button-holder-${id}`],
        playLink = this.$refs[`video-play-link-${id}`],
        videoPlaceholder = buttonHolder.nextElementSibling,
        isMobile = Helpers.isUserMobile(false) && !Helpers.isUserTablet();

      if (!modalVideo || !videoPlaceholder || !buttonHolder || !playLink) {
        console.warn(
          `Error opening video modal, elements missing`,
          modalVideo,
          videoPlaceholder,
          buttonHolder,
          playLink
        );
        return;
      }

      this.playLink = playLink;

      if (isMobile) {
        videoPlaceholder.classList.remove('hidden');
        // hide the buttons and the poster.
        buttonHolder.classList.add('hidden');
        buttonHolder.previousElementSibling.classList.add('hidden');
        playLink.classList.add('hidden');
        // Render the video
        Common.assets.renderVideo(videoPlaceholder.firstElementChild, true);
        return;
      }

      // If we are opening the same video just resume playing.
      if (this.player && this.videoModal.videoId && this.videoModal.videoId === id) {
        this.player.play();
        this.videoModal.isOpen = true;
        return;
      }

      if (this.player) {
        this.player.remove();
      }

      modalVideo.innerHTML = videoPlaceholder.innerHTML;
      this.videoModal.videoId = id;
      this.player = Common.assets.renderVideo(modalVideo.firstElementChild, true);
      this.videoModal.isOpen = true;
    },

    /**
     * Close the video modal.
     */
    closeVideoModal() {
      this.videoModal.isOpen = false;
      if (this.player) {
        this.player.pause();
      }

      if (this.playLink) {
        this.playLink.classList.remove('hidden');
      }
    }
  }
};
