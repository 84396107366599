import axios from 'axios';
import { LOCATION_EXP_TIME } from './constants.js';

class UserLocation {
  static LS_KEY = 'userLocationData';

  constructor() {
    let userLocationData = {};

    if (window.sessionStorage.getItem(UserLocation.LS_KEY)) {
      try {
        userLocationData = JSON.parse(window.sessionStorage.getItem(UserLocation.LS_KEY));
      } catch (exception) {
        console.error(exception);
      }
    }

    if (userLocationData && new Date() - userLocationData.timestamp < LOCATION_EXP_TIME) {
      this.promise = new Promise((resolve) => {
        resolve(userLocationData);
      });
    } else {
      this.promise = this.getUserLocation();
    }
  }

  /**
   * Get the the location via Promise.
   * @return {*|Promise<any>|Window.Promise}
   */
  get location() {
    return this.promise;
  }

  /**
   * Api call to get the user location from the user IP.
   * @return {Promise<{data: any, timestamp: number} | never>}
   */
  getUserLocation() {
    return axios.get('/actions/dot-com/api/geo').then(function(resp) {
      let data = {
        data: resp.data,
        timestamp: new Date().getTime()
      };
      try {
        window.sessionStorage.setItem('userLocationData', JSON.stringify(data));
      } catch (exception) {
        console.error(exception);
      }
      return data;
    });
  }
}

export default new UserLocation();
