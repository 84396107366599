import { LOCATION_EXP_TIME, getUserLocaleAsSiteHandle } from './constants';
import geolocation from './user_location';
import axios from 'axios';

/**
 * Uses the users location data to get the Nearby Office data
 */
export default function() {
  let nearbyOffice = null;
  try {
    if (window.localStorage.getItem('nearbyOffice')) {
      nearbyOffice = JSON.parse(window.localStorage.getItem('nearbyOffice'));
    }
  } catch (exception) {
    // Silence any errors to do with localStorage or JSON parsing.
  }

  if (
    nearbyOffice &&
    new Date() - nearbyOffice.timestamp < LOCATION_EXP_TIME &&
    nearbyOffice.data &&
    Object.keys(nearbyOffice.data).length
  ) {
    return new Promise((resolve) => {
      resolve(serializeOffice(nearbyOffice.data));
    });
  } else {
    return geolocation.location
      .then((userLocation) => {
        if (
          !userLocation ||
          !userLocation.data ||
          !userLocation.data.latitude ||
          !userLocation.data.longitude
        ) {
          throw 'Invalid geolocation data';
        }

        let localeParam = '';
        let locale = getUserLocaleAsSiteHandle();
        if (locale) {
          localeParam = '&locale=' + locale;
        }

        let url =
          '/actions/dot-com/api/find-nearby-office?latitude=' +
          userLocation.data.latitude +
          '&longitude=' +
          userLocation.data.longitude +
          '&country=' +
          userLocation.data.countryName +
          localeParam;

        return axios.get(encodeURI(url));
      })
      .then((resp) => {
        if (typeof resp.data === 'object' && Object.keys(resp.data).length > 0) {
          try {
            window.localStorage.setItem(
              'nearbyOffice',
              JSON.stringify({
                data: resp.data,
                timestamp: new Date().getTime()
              })
            );
          } catch (e) {
            // Silence local storage errors
          }

          return serializeOffice(resp.data);
        } else {
          throw 'No office found';
        }
      });
  }
}

/**
 * Formats the address object into the map link url.
 * @param {Object} address The address encoded for URLs.
 * @return {string}
 */
const formatMapLink = function(address) {
  if (!address) {
    return '';
  }

  return 'https://www.google.com/maps?q=' + address;
};

const serializeOffice = function(office) {
  if (office) {
    return {
      ...office,
      mapUrl: formatMapLink(office.addressQueryParam)
    };
  }

  return null;
};
