// Map of 6sense user data to GA custom dimensions.
const USER_DIMENSION_MAP = {
  company_industry: 'dimension1',
  company_name: 'dimension2',
  company_domain: 'dimension3',
  offices: 'dimension4',
  services: 'dimension5',
  page_type: 'dimension6',
  is_internal_user: 'dimension7'
};

class Analytics {
  constructor() {
    this.pendingCommands = [];
    this.ga4CustomDefs = {};
  }

  /**
   * Initialize Google Analytics 4. This will trigger a page view only the first time it's called.
   * @param {Boolean} [allowCookies=false] True if you want to let GA persist cookies.
   * @param {Object} [userData={}] Additional user data.
   */
  createGA4(allowCookies = false, userData = {}) {
    if (userData) {
      if (userData.services) {
        this.ga4CustomDefs['expertise'] = userData.services;
      }
      if (userData.offices) {
        this.ga4CustomDefs['office'] = userData.offices;
      }
      if (userData.page_type) {
        this.ga4CustomDefs['page_type'] = userData.page_type;
      }
    }

    if (allowCookies) {
      window.gtag('consent', 'update', {
        ad_storage: 'granted',
        analytics_storage: 'granted'
      });
      window.gtag('set', 'ads_data_redaction', false);
    } else {
      window.gtag('consent', 'update', {
        ad_storage: 'denied',
        analytics_storage: 'denied'
      });
      window.gtag('set', 'ads_data_redaction', true);
    }

    // Track a page view if we haven't done one yet.
    if (!this.ga4Initialized) {
      this.ga4Initialized = true;

      window.gtag('event', 'page_view', {
        page_title: document.title,
        page_location: document.location.href,
        ...this.ga4CustomDefs
      });

      // Adding custom definition outside the event scope so we can track using user scope.
      if (userData && userData.is_internal_user) {
        window.gtag('set', 'user_properties', {
          is_internal_user: true
        });
      }
    }

    let campaignData = this.getCampaignData();
    if (campaignData) {
      window.gtag('set', campaignData);
    }
  }

  /**
   * Setup link tracking for all links on the site.
   */
  setupLinkTrackers() {
    document.body.addEventListener('click', (e) => {
      let el = e.target;

      // Card clicks will happen on IMG tags, we want to track those as well IMG -> PICTURE -> A
      if (el.tagName !== 'A') {
        el = el.closest('A');
      }

      if (el && el.tagName === 'A') {
        let data = {
          link: el.href
        };
        if (el.dataset.action) {
          data.action = el.dataset.action;
        }

        this.trackEvent('Link Click', data);
      }
    });
  }

  /**
   * Track an event in Google Analytics.
   * https://support.google.com/analytics/answer/11259997?hl=en
   * @param {String} name The name of the event.
   * @param {Object} params The params to pass to the event
   */
  trackEvent(name, params) {
    window.gtag('event', name, {
      ...params,
      ...this.ga4CustomDefs
    });
  }

  /**
   * Handle the campaign data. This will parse campaign info from the URL or read from storage
   * if it was previously saved. When cookies aren't in play, GA won't automatically
   * carry campaign info between page changes.
   * @returns {Object} The campaign data or null.
   */
  getCampaignData() {
    let campaignData = null;
    try {
      let params = window.location.search
        ? window.location.search.replace(/^\?/, '').split('&')
        : [];
      campaignData = params
        .filter((p) => /^utm_/i.test(p))
        .reduce((obj, p) => {
          if (!obj) {
            obj = {};
          }

          let parts = p.split('=');
          let name = parts[0].toLocaleLowerCase();
          if (name === 'utm_source') {
            obj['campaign_source'] = decodeURIComponent(parts[1]);
          } else if (name === 'utm_medium') {
            obj['campaign_medium'] = decodeURIComponent(parts[1]);
          } else if (name === 'utm_campaign') {
            obj['campaign_name'] = decodeURIComponent(parts[1]);
          } else if (name === 'utm_term') {
            obj['campaign_term'] = decodeURIComponent(parts[1]);
          } else if (name === 'utm_content') {
            obj['campaign_content'] = decodeURIComponent(parts[1]);
          }
          return obj;
        }, null);

      if (campaignData) {
        window.sessionStorage.setItem('utm', JSON.stringify(campaignData));
      }

      if (!campaignData) {
        let json = window.sessionStorage.getItem('utm');
        if (json) {
          campaignData = JSON.parse(json);
        }
      }
    } catch (err) {
      // Silence, don't care about storage or json errors.
      console.error(err);
    }

    return campaignData;
  }
}

// Export a singleton instance.
var analytics = new Analytics();
export default analytics;
