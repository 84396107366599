export default {
  bind: function(el, binding) {
    // method: The method passed to the directve to fire on a click-outside.
    // exclude: An array of CSS selectors that, when matched, prevents the method from firing.
    var method, exclude;
    // the expression passed evaluated to a known function, cool to use
    if (typeof binding.value === 'function') {
      method = binding.value;
    } else if (typeof binding.value === 'object') {
      method = binding.value.method;
      exclude = binding.value.exclude;
    }

    if (!method) {
      throw 'Invalid method passed to binding expression for directive v-click-outside.';
    }

    el._clickOutsideHandler = function(event) {
      if (!event) {
        return;
      }

      const target = event.target;
      const containsOrIsEl = el === target || el.contains(target);
      const isExclusionOrChildOf = exclude.reduce((acc, cur) => {
        return acc || target.matches(cur) || target.closest(cur);
      }, false);

      if (method && !containsOrIsEl && !isExclusionOrChildOf) {
        // This will execute the method from the directive expression when calling the component.
        method(event);
      }
    };
    document.body.addEventListener('click', el._clickOutsideHandler);
  },

  unbind: function(el) {
    document.body.removeEventListener('click', el._clickOutsideHandler);
  }
};
