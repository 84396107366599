import Helpers from './util/helpers.js';
import { debounce } from 'debounce';
import { CLASSES } from './util/constants';
import analytics from './util/analytics';
import Component from './util/component';

class Navigation extends Component {
  static FLOATING_NAV_CLASS = 'floating';
  static ANIMATION_CLASS = 'hide';
  static NAV_ANIM_DURATION = 1000;
  static DEFAULT_NAV_COLOR = '#FFF';
  static DEFAULT_NAV_BRIGHTNESS = 'light';

  /**
   * Create a Headline.
   */
  constructor() {
    super(document.getElementById('main-nav'));

    // Elements
    this.el = document.getElementById('main-nav');
    this.openNav = this.el.querySelector('.main-nav-open');

    if (!this.el.querySelector('nav.menu')) {
      // If we have an empty nav (pitches) do not bind any of the events and hide the button.
      this.openNav.classList.add(CLASSES.hidden);
      return;
    }
    this.navModal = document.querySelector('.navigation-modal ');
    this.markHolder = this.navModal.querySelector('.mark-wrapper');
    this.closeNav = this.navModal.querySelector('.main-nav-close');
    this.lastFocusedElement = null;
    // If we have a submenu with the active  class that menu needs to be always active.
    // The animation is on the parent, we need to delay the  animation class until
    // position calculations complete.
    this.alwaysActiveMenu = this.el.querySelector('.submenu-wrapper .submenu.active');

    this.scrollPos = Helpers.getScroll().y;
    this.preventScrollData = [];

    if (this.el) {
      window.addEventListener('scroll', this.pageScrollHandler.bind(this));
      this.navModal.addEventListener('scroll', this.modalScrollHandler.bind(this));
      this.openNav.addEventListener('click', this.toggle.bind(this));
      this.closeNav.addEventListener('click', this.toggle.bind(this));
      let navMenu = this.el.querySelector('nav.menu');
      if (navMenu) {
        navMenu.addEventListener('mouseover', this.menuMouseoverHandler.bind(this));
        navMenu.addEventListener('mouseout', this.menuMouseoutHandler.bind(this));
      }

      window.addEventListener('load', this.subMenuHandler.bind(this));
      window.addEventListener('resize', debounce(this.subMenuHandler.bind(this), 50));
    }
  }

  /**
   * Setup the page navigation color, logo and dark class.
   * @param {String} backgroundColor The hex color value for the nav background.
   * @param {String} brightness dark or light.
   */
  setupColor(backgroundColor, brightness) {
    let logoElement = this.el.querySelector('.logo'),
      logoTemplate = this.el.querySelector('.use-logo-' + brightness);

    this.el.classList.add(brightness);
    this.el.querySelector('.background').style.backgroundColor = backgroundColor;

    if (logoElement && logoTemplate) {
      logoElement.innerHTML = logoTemplate.textContent;
    } else {
      console.warn(
        'You did a bad thing!',
        'The .logo element  or the logo template in the navigation are missing!',
        logoElement,
        logoTemplate
      );
    }
  }

  /**
   * Submenu alignment to centered under parent.
   */
  subMenuHandler() {
    let submenus = this.el.querySelectorAll('.submenu-wrapper .submenu');

    for (let i = 0; i < submenus.length; i++) {
      // Compensate for the page padding $column = 1/18 of vw
      let submenu = submenus[i],
        submenuWidth = (submenu.offsetWidth / 18) * 17,
        alignEl = this.el.querySelector(`.menu .${submenu.dataset.submenuFor}`),
        offset = alignEl.offsetLeft + alignEl.offsetWidth / 2;

      let childWidth = 0;

      for (let i = 0; i < submenu.children.length; i++) {
        childWidth += submenu.children[i].offsetWidth;
      }

      let padding = offset - childWidth / 2;

      if (padding < 0) {
        // If we end up with negative padding set default
        submenu.style.removeProperty('padding-left');
        // If there is room for the calculate padding apply it.
      } else if (padding + childWidth <= submenuWidth) {
        submenu.style.paddingLeft = padding + 'px';
      } else {
        // If the calculated padding is bigger than the room we have align all the way to the right.
        submenu.style.paddingLeft = submenuWidth - childWidth + 'px';
      }
    }

    // After we are done with the calculations add the active class to the parent and trigger anim
    if (this.alwaysActiveMenu) {
      this.alwaysActiveMenu.parentElement.classList.add('active');
      let menu = this.el.querySelector(`.menu .${this.alwaysActiveMenu.dataset.submenuFor}`);
      if (menu) {
        menu.classList.add('always-active');
      }
    }
  }

  /**
   * Page scroll handler.
   */
  pageScrollHandler() {
    // If we are preventing scroll, we do not want the navigation to show up.
    if (document.body.classList.contains(Helpers.PREVENT_SCROLL_CLASS)) {
      return;
    }

    let headlineHeight = window.$common.headline ? window.$common.headline.getHeight() : 300;

    let scrollPos = Helpers.getScroll().y;
    // Hide the nav once it leaves the viewport.
    if (scrollPos > this.el.offsetHeight * 2 && scrollPos < headlineHeight) {
      this.el.classList.add(Navigation.ANIMATION_CLASS);
    } else if (scrollPos > headlineHeight) {
      // Make the navigation fixed once we are not over the headline.
      scrollPos < this.scrollPos
        ? this.el.classList.remove(Navigation.ANIMATION_CLASS)
        : this.el.classList.add(Navigation.ANIMATION_CLASS);
      this.el.classList.add(Navigation.FLOATING_NAV_CLASS);
    } else {
      this.el.classList.remove(Navigation.FLOATING_NAV_CLASS);
      this.el.classList.remove(Navigation.ANIMATION_CLASS);
    }
    // saves the new position for iteration.
    this.scrollPos = scrollPos;
  }

  /**
   * Hides and shows the appropriate menu on mouse in.
   * @param e
   */
  menuMouseoverHandler(e) {
    let t = e.target;
    // We only care on a hovers
    if (t.tagName !== 'A') {
      return;
    }

    // If the tag does not have a submenuSelector attr it doesn't have a submenu
    if (!t.dataset.submenuSelector) {
      return;
    }

    let subMenu = this.el.querySelector(`.submenu-wrapper .${t.dataset.submenuSelector}`);
    if (!subMenu) {
      return;
    }

    // If we already have an active element we are on a page with the context menu.
    if (this.alwaysActiveMenu) {
      this.alwaysActiveMenu.classList.remove('active');
    }

    subMenu.classList.add('active');
    subMenu.parentElement.classList.add('active');
    this.lastsHoverNavEl = subMenu;
  }

  /**
   * Hides and shows the appropriate menu on mouse out.
   * @param e
   */
  menuMouseoutHandler(e) {
    if (!this.lastsHoverNavEl) {
      return;
    }

    this.lastsHoverNavEl.classList.remove('active');
    // If we have an always active menu do not remove the active class from the parent
    // and make sure you restore it on mouse out.
    if (this.alwaysActiveMenu) {
      this.alwaysActiveMenu.classList.add('active');
    } else {
      this.lastsHoverNavEl.parentElement.classList.remove('active');
    }

    this.lastsHoverNavEl = null;
  }

  /**
   * Scrolling in the navigation modal.
   * @param {Event} e The event.
   */
  modalScrollHandler(e) {
    if (this.markHolder) {
      this.markHolder.style.top = this.navModal.scrollTop + 'px';
    }
  }

  /**
   * Toggle the navigation.
   */
  toggle() {
    if (this.navModal.classList.contains(CLASSES.activeModalClass)) {
      window.$common.footer.show();
      window.$common.content.show();
      this.navModal.classList.remove(CLASSES.activeModalClass);
      Helpers.restoreScroll(this.preventScrollData);
      this.lastFocusedElement.focus();
    } else {
      this.lastFocusedElement = document.activeElement;
      this.navModal.setAttribute('aria-expanded', 'true');
      this.navModal.setAttribute('aria-hidden', 'false');
      this.navModal.classList.add(CLASSES.activeModalClass);
      // Prevent the scroll after the nav animation has completed.
      setTimeout(() => {
        this.preventScrollData = Helpers.preventScroll();
        this.navModal.focus();
        window.$common.footer.hide();
        window.$common.content.hide();
      }, Navigation.NAV_ANIM_DURATION);
    }
  }

  /**
   * TODO: Bind this when we enable the language selector.
   * Select page language
   */
  selectLang(event) {
    analytics.trackEvent('Link Click', { link: event.target.value, action: 'Nav Menu' });
    window.location = event.target.value;
  }
}

export default Navigation;
