import axios from 'axios';
import { getUserLocaleAsSiteHandle } from './constants';

class CategoryView {
  static SELECTOR = '.category-view-section';

  constructor() {
    let el = document.querySelector(CategoryView.SELECTOR);
    if (!el) {
      return;
    }
    this.el = el;
    this.template = this.el.querySelector('script.loading-template');
    let button = this.el.querySelector('button.see-more');
    if (button) {
      button.addEventListener('click', this.loadCampaignGridPage.bind(this));
    }
  }

  /**
   * Gets the card html from the service and campaign attributes and updates the cards html.
   * @param {Event} e The event.
   */
  loadCampaignGridPage(e) {
    let page = parseInt(e.target.dataset.page),
      url = e.target.dataset.url,
      service = e.target.dataset.service,
      categories = e.target.dataset.categories,
      cards = this.el.querySelector('.cards'),
      // the current state of the html is saved in the html var and the loading wrapper is
      // appended. If the request is successful we add the new html to the var and at the
      // second then (finally) we replace the html with the one in the var.
      // This is because we want to get rid of the loading wrapper regardless if the
      // request failed or not.
      html = cards.innerHTML,
      locale = getUserLocaleAsSiteHandle();

    cards.innerHTML += this.template.innerHTML;
    cards.classList.add('is-loading');
    page++;

    axios
      .get(url, { params: { page, service, categories, locale } })
      .then((resp) => {
        if (resp.data.error) {
          console.error(resp.data.error);
          return;
        }
        e.target.dataset.page = page;
        if (!resp.data.hasMore) {
          e.target.parentElement.classList.add('hidden');
        }

        if (!resp.data.empty) {
          html += resp.data.html;
        }
      })
      .catch(console.error)
      .then(() => {
        cards.innerHTML = html;
        cards.classList.remove('is-loading');
      });
  }
}

export default CategoryView;
