import './polyfill.js';
import svg4everybody from 'svg4everybody';
import { debounce } from 'debounce';
import Headline from './_headline.js';
import Navigation from './_navigation.js';
import Body from './util/body.js';
import CategoryView from './util/category-view.js';
import Content from './_content.js';
import Footer from './_footer.js';
import Assets from './util/assets';
import Helpers from './util/helpers.js';
import Vue from 'vue';
import VueSingleton from './_vue-signleton.js';
import Prism from 'prismjs';

svg4everybody();

class Common {
  static ACTIVE_MODAL_CLASS = 'active';
  static HIDDEN_CLASS = 'active';

  /**
   *
   */
  constructor() {
    // Add a reference to common to vue.
    Vue.prototype.$common = this;
    // Add reference to common in window.
    window.$common = this;
    window.addEventListener('DOMContentLoaded', this.boot.bind(this));
  }

  boot() {
    try {
      // NOTE: Vue has to be instantiated before all other components because it re-renders the dom.
      this.vue = VueSingleton.instantiate();
      this.headline = new Headline();
      this.navigation = new Navigation();
      this.body = new Body();
      this.categoryView = new CategoryView();
      this.content = new Content();
      this.footer = new Footer();
      this.assets = Assets.boot();
      Prism.highlightAll();
    } catch (e) {
      console.warn(e);
    }

    // For non-mobile/tablets, add page click handler to open small window for social share clicks.
    if (!Helpers.isUserMobile()) {
      window.addEventListener('click', this.socialLinkClickHandler.bind(this));
    }

    // Hack to get prod.vml.com testing have consistent URLs.
    if (window.location.hostname === 'prod.vml.com') {
      const publicUrl = 'https://www.vml.com';
      const updateLinks = debounce(function() {
        document.querySelectorAll(`a[href^="${publicUrl}"]`).forEach((a) => {
          let newHref = a.getAttribute('href').substring(publicUrl.length);
          if (!newHref) {
            newHref = '/';
          }
          a.setAttribute('href', newHref);
        });
      }, 500);
      updateLinks();

      // Watch for content changes on work listing and update links then too.
      const observer = new MutationObserver(updateLinks);
      observer.observe(document.body, { childList: true, subtree: true });
    }

    if (window.takeoverSnippet) {
      window.takeoverSnippet(this);
    }

    //For pages with more than one translation available
    //allows user to expand and collapse
    let slideOut = document.querySelector('.lang-slideout');
    let activeLang = document.querySelector('.lang-tab');

    if (slideOut) {
      document.addEventListener('click', function(event) {
        if (event.target.closest('.lang-tab')) {
          slideOut.classList.add('expand');
          activeLang.classList.remove('active');
        } else {
          // If user clicks outside the element, collapse
          slideOut.classList.remove('expand');
          activeLang.classList.add('active');
        }
      });

      document.addEventListener('keyup', function(event) {
        if (event.keyCode === 13 || event.keyCode === 9) {
          // If user hits enter on the language tab or tabs through the links
          // the language section will display
          if (event.target.closest('.lang-tab, .lang-link')) {
            slideOut.classList.add('expand');
            activeLang.classList.remove('active');
          } else {
            // Once user tabs out of the lang list the
            // language section will collapse back to the tab
            slideOut.classList.remove('expand');
            activeLang.classList.add('active');
          }
        }
      });
    }

    // Sections with max number of items will remain hidden until
    // click event is triggered.
    document.querySelectorAll('button.load-more').forEach((el) => {
      el.addEventListener('click', (event) => {
        let selector = event.target.dataset.selector;
        if (selector) {
          let hidden = document.querySelectorAll(`${selector}.hidden`);
          hidden.forEach((el) => {
            el.classList.remove('hidden');
          });
        }
        event.target.parentNode.remove();
      });
    });
  }

  /**
   * Share click handler.
   * @param {event} event The event.
   */
  socialLinkClickHandler(event) {
    let socialLink = event.target.closest('.social-share-link');
    if (socialLink) {
      event.preventDefault();
      const win = window.top;
      const w = Math.min(600, win.screen.availWidth),
        h = Math.min(600, win.screen.availHeight),
        y = win.outerHeight / 2 + win.screenY - h / 2,
        x = win.outerWidth / 2 + win.screenX - w / 2;
      window.open(
        socialLink.href,
        'Social Share',
        `width=${w},height=${h},top=${y},left=${x},toolbar=0`
      );
    }
  }
}
const common = new Common();
export default common;
