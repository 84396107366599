const _linkedin_partner_id = '1140386';

const initialize = function() {
  let img = document.createElement('img');
  img.src = `https://px.ads.linkedin.com/collect/?pid=${_linkedin_partner_id}&fmt=gif`;
  img.style.position = 'absolute';
  img.style.left = '-1000px';
  img.setAttribute('aria-hidden', 'true');
  document.body.appendChild(img);

  // TODO evaluate if this is really needed or the above img tag is enough.
  /*
  if (!window.lintrk) {
    window.lintrk = function(a, b) {
      window.lintrk.q.push([a, b]);
    };
    window.lintrk.q = [];
  }

  window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
  window._linkedin_data_partner_ids.push(_linkedin_partner_id);

  let tag = document.createElement('script');
  tag.type = 'text/javascript';
  tag.async = true;
  tag.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';

  const head = document.getElementsByTagName('head')[0];
  head.appendChild(tag);
  */
};

export default initialize;
