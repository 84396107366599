<template>
  <section class="filter-section cnt-fc vs-m">
    <section
      :class="{ active: showFilters, 'gt-2': Object.keys(filters).length > 2 }"
      class="filters"
    >
      <div
        v-for="(val, key) in normalizedFilters"
        :key="'header' + key"
        class="tab-section"
        v-click-outside="{ method: close, exclude: ['.tab-section'] }"
      >
        <button
          class="tab link"
          @keydown.space.prevent
          @click="select(key)"
          :class="{ active: active === key }"
        >
          {{ labels[key] || key }}
          <span v-if="selected[key].length">({{ selected[key].length }})</span>
        </button>
        <div class="tab-body" :style="getTabBodyStyles(val.length)" v-show="active === key">
          <div v-for="item in val" class="select" :key="isNaN(item.id) ? item.value : item.id">
            <template v-if="!isNaN(item.id)">
              <label :for="key + '-' + item.id" :aria-label="item.title">
                <input
                  :id="key + '-' + item.id"
                  v-model="selected[key]"
                  type="checkbox"
                  :value="item.slug"
                  class="checked"
                />
                <span
                  class="checkbox"
                  role="checkbox"
                  :aria-checked="isOptionSelected(key, item.slug).toString()"
                  :aria-labelledby="item.title"
                ></span>
                {{ item.title }}
              </label>
            </template>
            <template v-else>
              <label :for="key + '-' + item.value">
                <input
                  :id="key + '-' + item.value"
                  v-model="selected[key]"
                  type="checkbox"
                  :value="item.value"
                  class="checked"
                />
                <span
                  class="checkbox"
                  role="checkbox"
                  :aria-checked="isOptionSelected(key, item.slug).toString()"
                  :aria-labelledby="item.title"
                ></span>
                {{ item.label }}
              </label>
            </template>
          </div>
        </div>
        <div class="selection-container">
          <span v-for="item in selectedValues[key]" :key="`${key}-${item}`" class="selections">
            <span class="selected-options">
              {{ item }}
              <button :aria-label="`${labelRemove} ${item}`" @click="removeFromSelected(key, item)">
                <svg
                  class="filter-remove"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 11 11"
                  aria-hidden="true"
                >
                  <path
                    class="st0"
                    d="M11 1.5L9.5 0l-4 4-4-4L0 1.5l4 4-4 4L1.6 11l3.9-4 4 4L11 9.5l-4-4z"
                  />
                </svg>
              </button>
            </span>
          </span>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import ClickOutside from '../directives/click-outside.js';
import { debounce } from 'debounce';
import axios from 'axios';
import analytics from '../util/analytics.js';
import Helpers from '../util/helpers.js';

export default {
  name: 'entry-filter',
  directives: { ClickOutside },
  props: {
    path: String,
    labels: Object,
    filters: Object,
    pageSize: Number,
    value: Boolean,
    labelRemove: {
      default: 'Remove',
      type: String
    }
  },

  data() {
    let filters = {},
      selected = {},
      checkedAll = {},
      params = Helpers.deparam(document.location.search, true);

    Object.keys(this.filters).forEach((key) => {
      try {
        filters[key] = JSON.parse(this.filters[key]);
        selected[key] = params[key] ? params[key] : [];
        checkedAll[key] = selected[key].length === filters[key].length;
      } catch (e) {
        console.error('Error processing filter json', e);
      }
    });

    return {
      url: '',
      page: 0,
      selected: selected,
      normalizedFilters: filters,
      showFilters: false,
      active: '',
      scrollPosition: 0,
      lastFocusedElement: null,
      mainNav: document.getElementById('main-nav'),
      checkedAll: checkedAll
    };
  },

  watch: {
    selected: {
      handler: debounce(function() {
        this.filterChange();
      }, 600),
      deep: true
    }
  },

  created: function() {
    this.$parent.$on('get-data', this.getData);
  },

  mounted() {
    // Track initial filters if we have them.
    if (window.location.search && window.location.search.length > 1) {
      analytics.trackEvent('Filter', {
        page: window.location.pathname.replace(/\//g, ''),
        filter: window.location.search.replace(/^\?/, '')
      });
    }
  },

  computed: {
    /**
     * Object of selected labels/titles generated from the selected slugs.
     */
    selectedValues: function() {
      let val = {};
      Object.keys(this.normalizedFilters).forEach((key) => {
        val[key] = this.normalizedFilters[key]
          .filter((item) => this.selected[key].includes(!isNaN(item.id) ? item.slug : item.value))
          .map((item) => (!isNaN(item.id) ? item.title : item.label));
      });
      return val;
    }
  },

  methods: {
    /**
     * Reset the entries one the user changes the filters.
     */
    filterChange() {
      this.page = 0;
      this.getData(false);
    },

    /**
     * Show or hide the filters.
     */
    toggleFilter() {
      // Make sure that the main nav in never open when we have the filters open.
      this.mainNav.classList.add('scroll-up');
      this.showFilters = !this.showFilters;
    },

    /**
     * Check if an option is selected.
     * @param {String} key the key.
     * @param {String} value the val.
     * @return {Boolean}
     */
    isOptionSelected(key, value) {
      if (!this.selected[key]) {
        return false;
      }
      return this.selected[key].includes(value);
    },

    /**
     * Select the filter tab.
     * @param {String} key The tab key.
     */
    select(key) {
      if (this.active === key) {
        this.active = '';
      } else {
        this.active = key;
      }
    },

    /**
     * Close the filters dropdown.
     */
    close: function() {
      this.active = '';
    },

    /**
     * Remove an item from the selected array.
     * @param {String} key The key of the array to remove from.
     * @param {String} label The label to remove.
     */
    removeFromSelected(key, label) {
      let obj = this.normalizedFilters[key].find(
        (item) => (!isNaN(item.id) ? item.title : item.label) === label
      );
      if (!obj) {
        return;
      }

      let index = this.selected[key].indexOf(!isNaN(obj.id) ? obj.slug : obj.value);
      if (index !== -1) {
        this.selected[key].splice(index, 1);
      }
    },

    /**
     * Get the data when the user click the get more button.
     * @param {Boolean} nextPage if we are incrementing the page or not.
     */
    getData(nextPage = true) {
      let page = this.page + (nextPage ? 1 : 0),
        params = [];

      // Indicate we're loading to parent
      this.$emit('input', true);

      Object.keys(this.selected).forEach((key) => {
        this.selected[key].forEach((param) => {
          params.push(key + '=' + param);
        });
      });

      this.url = `/${this.path}?xhr=1&page=${page}${params.length ? '&' : ''}${params.join('&')}`;
      axios
        .get(this.url, {
          headers: {
            'X-Requested-With': 'XMLHttpRequest'
          }
        })
        .then((resp) => {
          if (this.url !== resp.config.url) {
            return;
          }
          let html = resp.data ? resp.data.trim() : '',
            hasMore = !!resp.headers['has-more'];
          if (html.length > 0) {
            if (nextPage) {
              this.page += 1;
            }
          }

          this.$emit('data', html, nextPage, hasMore);

          window.history.replaceState(
            {},
            document.title,
            window.location.pathname + `${params.length ? '?' : ''}${params.join('&')}`
          );

          // If we are going to the next page the user clicked the load more button.
          if (nextPage) {
            analytics.trackEvent('Show More', {
              page: window.location.pathname.replace(/\//g, ''),
              filter: params.join('&')
            });
          } else {
            // Filter change event
            analytics.trackEvent('Filter', {
              page: window.location.pathname.replace(/\//g, ''),
              filter: params.join('&')
            });
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .then(() => {
          this.$emit('input', false);
        });
    },

    /**
     * Return inline styles for the tabBody element. These
     * are used when on a non-mobile browser and we want to
     * show the tab body absolutely floating above other content.
     * @param {Number} itemCount Number of items in the tab body.
     * @return {Object} The styles.
     */
    getTabBodyStyles(itemCount) {
      let height = 400;
      if (itemCount < 11) {
        // 36 = rought height of each option.
        // 16 = bottom padding of the tabyBody.
        height = itemCount * 36 + 16;
      }

      return {
        height: `${height}px`
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/common';

.filter-section {
  width: 100%;
}

.filter-toggle {
  font-size: 1rem;
  padding: 1rem 3rem 1rem 2rem;
  outline: none;

  &::after {
    position: absolute;
    content: '';
    right: 1.8rem;
    top: 1.1rem;
    width: 0.5rem;
    height: 0.5rem;
    border-right: 0.15rem solid $black;
    border-bottom: 0.15rem solid $black;
    transform: rotate(45deg);
  }

  &.active::after {
    transform: rotate(-45deg);
  }
}

.filters {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0 1rem 0;
  width: 100%;
  box-sizing: border-box;
  position: relative;

  .tab-section {
    height: 100%;
    width: 46%;
    padding-bottom: 1rem;
    position: relative;
  }

  .tab {
    position: relative;
    text-transform: uppercase;
    width: 100%;
    text-align: left;
    padding: 1rem 2.3rem 5px 0;

    font-size: 1rem;
    margin: 0 1rem 0 0;
    background: $white;
    color: $black;
    outline: none;
    border-bottom: 1px solid black;

    &::after {
      content: '';
      position: absolute;
      top: 35%;
      right: 1%;
      width: 1px;
      border: solid $black;
      border-width: 0 1px 1px 0;
      display: inline-block;
      padding: 6px;
      transform: rotate(45deg);
    }

    &.active {
      position: relative;
      border-bottom: none;

      &::after {
        content: '';
        position: absolute;
        top: 35%;
        right: 1%;
        width: 1px;
        border: solid $black;
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 6px;
        transform: rotate(-135deg);
      }
    }

    &:focus {
      border: 2px solid $grey-darkest;
    }
  }

  .tab-body {
    position: relative;
    order: 5;
    top: 1.8rem;
    left: 0;
    height: 100%;
    max-height: 27vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    background: $white;
    color: $black;
    padding: 0 0 1rem 0;
    align-content: flex-start;
    overflow-y: auto;
    box-sizing: border-box;

    .select {
      text-transform: capitalize;
      padding: 0.5rem;
      box-sizing: border-box;
    }

    label {
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      max-width: 100%;
    }

    input[type='checkbox'] {
      margin-right: 0.5rem;
      opacity: 0;
      width: 0;
      height: 0;
      position: absolute;

      ~ .checkbox {
        margin: 0 0.5rem 0 0;
        display: inline-block;
        position: relative;
        width: 1rem;
        height: 1rem;
        border: 2px solid $black;
      }

      &:focus ~ .checkbox {
        outline: 2px solid $grey-darkest;
      }

      &:checked ~ .checkbox {
        background: $black;

        &:before {
          content: '';
          display: block;
          position: absolute;
          width: 75%;
          height: 45%;
          border-bottom: 3px solid $white;
          border-left: 3px solid $white;
          box-sizing: border-box;
          top: 22.5%;
          left: 12.5%;
          transform: rotate(-45deg);
        }
      }
    }
  }

  &.gt-2 {
    .tab-section {
      width: 32%;
    }

    label {
      line-height: 1.5rem;
    }

    input[type='checkbox'] ~ .checkbox {
      min-width: 1rem;
    }
  }

  .selection-container {
    margin-top: 0.5rem;

    .selections {
      display: inline-block;
    }
  }

  .selected-options {
    display: flex;
    align-items: center;
    color: rgba(33, 35, 34, 0.7);
    background: rgba(33, 35, 34, 0.1);
    padding: 0.55rem;
    border-radius: 7px;
    margin: 0.5rem 0.5rem 0.5rem 0;

    button {
      background: transparent;
      padding: 5px;
      color: rgba(33, 35, 34, 0.7);
      border: 1px solid rgba(33, 35, 34, 0.7);
      border-radius: 50%;
      margin-left: 0.75rem;
      font-size: 0.5rem;
      display: flex;
    }

    .filter-remove {
      height: 0.5rem;
      width: 0.5rem;
    }
  }
}

// For tablet and up, make expanded filters absolute
// so they float over content.
@media screen and (min-width: $tablet) {
  .filters .tab-body {
    position: absolute;
    z-index: $z-dropdown;
    top: 40px;
    min-height: 100px;
    max-height: none;
    box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.2);
  }
}

@include tablet() {
  .filters {
    .tab-body {
      max-height: 25vh;
    }

    &.gt-2 {
      flex-direction: column;

      .tab-section {
        width: 75%;
        margin: 0 auto;
      }
    }
  }
}

@include mobile() {
  .filter-section {
    margin: 1rem 0 0;
  }
  .filters {
    padding: 2rem 0;
    flex-direction: column;

    .tab-section {
      display: block;
      width: 100%;
      margin-bottom: 1rem;
    }

    .tab {
      background: none;
      width: 100%;
      color: $black;
      padding: 1rem 0 0 0;
      position: relative;

      &::after {
        position: absolute;
        content: '';
        top: 40%;
        right: 1%;
        width: 1px;
        border: solid $black;
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 6px;
        transform: rotate(45deg);
      }

      &.active:after {
        top: 40%;
        right: 1%;
        width: 1px;
        border: solid $black;
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 6px;
        transform: rotate(-135deg);
      }
    }

    .tab-body {
      position: static;
      flex-direction: column;
      height: auto;
      padding-left: 0;
      padding-right: 0;

      .select {
        flex: 1 1 100%;
        width: 100%;
        padding-left: 0;
        max-width: none;
      }
    }

    &.gt-2 {
      .tab-section {
        width: 100%;
      }
    }
  }
}
</style>
