import gdpr from '../util/gdpr';

export default {
  methods: {
    gdprCheck() {
      if (gdpr.areFunctionalAllowed()) {
        gdpr.enableEmbeds(null);
      }
    }
  }
};
