export default {
  PREVENT_SCROLL_CLASS: 'prevent-scroll',

  /**
   * Test if a user is on a mobile device.
   * @param {boolean} excludeTablet True if we want to exclude tablet width devices.
   * False by default.
   * @return {boolean}
   */
  isUserMobile: (excludeTablet = false) => {
    let mobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ||
      window.innerWidth < 600;

    if (excludeTablet) {
      // TODO i think this should maybe go to <= 1024 because
      // often times we want a landscape ipad to be a tablet ;)
      mobile = mobile && window.innerWidth < 980;
    }

    return mobile;
  },

  /**
   * Pure screen size lookup.
   */
  isUserTablet: () => {
    return window.innerWidth > 600 && window.innerWidth <= 1024;
  },

  /**
   * Pure screen size lookup.
   */
  isUserDesktop: () => {
    return window.innerWidth > 1024;
  },

  /**
   * Returns an object containing page scroll.
   * @return {{x: number, y: number}}
   */
  getScroll() {
    let pos = {
      x: 0,
      y: 0
    };

    if (window.pageYOffset !== undefined) {
      pos.x = pageXOffset;
      pos.y = pageYOffset;
      return pos;
    } else {
      let r = document.documentElement,
        b = document.body;

      pos.x = r.scrollLeft || b.scrollLeft || 0;
      pos.y = r.scrollTop || b.scrollTop || 0;
    }

    return pos;
  },

  /**
   * Return the current widht and height of the viewport.
   * @return {{width: number, height: number}}
   */
  getViewportSize() {
    let height = 0,
      width = 0;

    if (
      document &&
      document.documentElement &&
      document.documentElement.clientHeight &&
      document.documentElement.clientWidth
    ) {
      height = document.documentElement.clientHeight;
      width = document.documentElement.clientWidth;
    } else {
      height = window.innerHeight;
      width = window.innerWidth;
    }

    return { width: width, height: height };
  },

  /**
   * Prevents the scroll and returns the state the page had before the prevent.
   * @return [Number, Element] The last y scroll position and the last focused element.
   */
  preventScroll() {
    let scrollPosition = this.getScroll().y,
      lastFocusedElement = document.activeElement;

    document.body.classList.add(this.PREVENT_SCROLL_CLASS);
    document.documentElement.classList.add(this.PREVENT_SCROLL_CLASS);

    return [scrollPosition, lastFocusedElement];
  },

  /**
   * Restores the scroll and the previous page state.
   * @param [Number, Element] The last y scroll position and the last focused element.
   */
  restoreScroll([scrollPosition, lastFocused]) {
    document.body.classList.remove(this.PREVENT_SCROLL_CLASS);
    document.documentElement.classList.remove(this.PREVENT_SCROLL_CLASS);
    if (lastFocused) {
      lastFocused.focus();
    }
    if (typeof scrollPosition === 'number') {
      window.scrollTo(0, scrollPosition);
    }
  },

  /**
   * Get the params object form query string.
   *
   * @param {String} querystring The query string.
   * @param {Boolean} insureArrays True if we want to always return params as an array.
   * @return {Object} The params object.
   */
  deparam(querystring, insureArrays) {
    // remove any preceding url and split
    querystring = querystring.substring(querystring.indexOf('?') + 1).split('&');
    var params = {};

    for (var i = querystring.length - 1; i >= 0; i--) {
      let pair = querystring[i].split('='),
        key = decodeURIComponent(pair[0]),
        value = decodeURIComponent(pair[1] || '');

      if (!params[key]) {
        params[key] = insureArrays ? [value] : value;
      } else if (!Array.isArray(params[key])) {
        params[key] = [params[key], value];
      } else {
        params[key].push(value);
      }
    }

    return params;
  },

  /**
   * Checks to see if we have a scroll on a given element and returns the width of the scrollbar.
   *
   * @param el
   * @return {Number} The with of the scroll bar if there is a scroll -1 if not.
   *
   */
  hasScroll(el) {
    if (el === document.body && window.innerWidth > document.body.clientWidth) {
      return window.innerWidth - document.body.clientWidth;
    }

    if (el.scrollHeight > el.clientHeight) {
      return el.offsetWidth - el.clientWidth;
    }

    return false;
  },

  /**
   * Randomly choose N promoted cards to display.
   *
   * @param selector {String} The selector.
   * @param count {Number} The number of entries to display.
   *
   */
  shuffleCards(selector, count = 2) {
    let cards = document.querySelectorAll(selector);

    if (cards && cards.length > count) {
      cards.forEach((card) => card.classList.add('hidden'));

      var indexes = [];
      while (indexes.length < count) {
        var r = Math.floor(Math.random() * cards.length);
        if (indexes.indexOf(r) === -1) indexes.push(r);
      }

      indexes.forEach((i) => {
        cards[i].classList.remove('hidden');
        cards[i].parentNode.insertBefore(cards[i], cards[0]);
      });
    }
  },

  /**
   *
   * @param {NodeList} nodeList nodeList
   */
  disableTabbing(nodeList) {
    nodeList.forEach((item) => {
      // Sets tabindex to -1 for given nodeList to disable tabbing to elements.
      item.setAttribute('tabindex', -1);
    });
  },

  /**
   *
   * @param {NodeList} nodeList nodeList
   */
  enableTabbing(nodeList) {
    nodeList.forEach((item) => {
      // Sets tabindex to 0 for given nodeList to enable tabbing to elements.
      item.setAttribute('tabindex', 0);
    });
  }
};
