<template>
  <section class="accordion-section" :class="{ focused: focused, classes }">
    <slot
      :allow-toggle="allowToggle"
      :active="active"
      :clickHandler="clickHandler"
      :arrowHandler="arrowHandler"
      :focusHandler="focusHandler"
      :blurHandler="blurHandler"
    >
    </slot>
  </section>
</template>

<script>
export default {
  name: 'Accordion',
  props: {
    classes: {
      type: [String, Array],
      default: ''
    },
    allowToggle: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      active: null,
      focused: false,
      triggers: null
    };
  },

  mounted() {
    // Because twig is rendering the template we cannot use refs
    this.triggers = this.$el.querySelectorAll('.accordion-trigger');
    this.descriptions = this.$el.querySelectorAll('.accordion-description');
  },

  methods: {
    /**
     * Handle clicks for the accordion headlines,
     * @param {Event} e The event.
     * @param {Number} index The index of the accordion element.
     */
    clickHandler(e, index) {
      if (this.allowToggle && this.active === index) {
        this.active = null;
      } else {
        this.active = index;
      }

      // Emit the current active description and the active flag.
      this.$emit('toggle', this.descriptions[index], this.active === index);
    },

    /**
     * Set the focus on the accordion if any of the titles has it.
     * @param {Event} e The event.
     */
    focusHandler(e) {
      this.focused = true;
    },

    /**
     * Remove the focus from the accordion when the titles lose it.
     * @param {Event} e The event.
     */
    blurHandler(e) {
      this.focused = false;
    },

    /**
     * Change over to the next element in the accoirdion if the arrow keys are hit.
     * @param {Event} e The event.
     * @param {Number} index The index of the focused title element.
     */
    arrowHandler(e, index) {
      let i = (index + (e.key === 'ArrowUp' ? -1 : 1)) % this.triggers.length;
      if (i < 0) {
        i = this.triggers.length - 1;
      }
      this.triggers[i].focus();
    }
  }
};
</script>
