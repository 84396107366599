import { CLASSES } from './constants';

class Component {
  /**
   * Generic site component.
   * @param {Element} el the component element.
   */
  constructor(el) {
    if (!el) {
      throw new Error('No component element found!');
    }

    this.el = el;
  }

  /**
   * Show the component.
   */
  show() {
    if (this.el) {
      this.el.classList.remove(CLASSES.hidden);
    }
  }

  /**
   * Hide the component.
   */
  hide() {
    if (this.el) {
      this.el.classList.add(CLASSES.hidden);
    }
  }

  /**
   * Return the height of the component root element.
   * @return {Number}
   */
  getHeight() {
    return this.el.getBoundingClientRect().height;
  }
}

export default Component;
