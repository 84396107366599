import '../../scss/gallery.scss';

import { debounce } from 'debounce';
import analytics from './analytics.js';
import helpers from './helpers';

// NOTE: if we are include the slider and vue the slider has to be initialized after it.
import { tns } from 'tiny-slider/src/tiny-slider';

// import objectFitImages from 'object-fit-images';
// import ResponsiveView from 'src/responsive.js';

const SIXTEEN_NINE = 1.777777778;
const PENDING_VIDEO_ANALYTICS = 'pendingAnalytics';
const SUPPORTS_PICTURE_EL = !!window.HTMLPictureElement;
const SUPPORTS_OBJECT_FIT = 'object-fit' in new Image().style;
const HAS_POLYFILL_OBJECT_FIT = /(object-fit|object-position)\s*:\s*([-\w\s%]+)/g;

class AssetUtil {
  constructor() {
    this.videoPlayers = {};
  }

  boot() {
    // Prevent duplicate bootstrapping.
    if (this.initialized) {
      return this;
    }
    this.initialized = true;

    // TODO revisit

    // Polyfill object-fit/object-position for IE
    // objectFitImages();
    // Clean up polyfill for Edge Picture elements
    // this.objectFitImagesEdge();

    // IOS devices can at times not fire unload page events. When this happens,
    // we see if we have pending video tracking analytics to send to google from the last page.
    let pendingAnalytics = window.sessionStorage.getItem(PENDING_VIDEO_ANALYTICS);
    if (pendingAnalytics) {
      try {
        pendingAnalytics = JSON.parse(pendingAnalytics);
        for (let videoName in pendingAnalytics) {
          let totalPlaybackTime = pendingAnalytics[videoName];
          analytics.trackEvent('Video', {
            action: 'Total View Time (seconds)',
            video: videoName,
            value: Math.round(totalPlaybackTime / 1000)
          });
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }

      try {
        window.sessionStorage.removeItem(PENDING_VIDEO_ANALYTICS);
      } catch (e) {
        // Silence browsers that don't have a session storage.
      }
    }

    this._registerPageLeaveHandler();
    this._initVideos();
    this._initSliders();

    return this;
  }

  /**
   * Initializes the sliders with a given selector and options.
   *
   * @param {String} selector The css selector for the slider.
   * @param {Object} options The tiny slider options.
   * @return {Array} The array of sliders.
   */
  // eslint-disable-next-line no-unused-vars
  _initSliders(selector = '.slider', options = null) {
    this.sliders = [];

    const isDesktopUser = helpers.isUserDesktop(),
      isMobileUser = helpers.isUserMobile(true),
      isTabletUser = helpers.isUserTablet();

    // Don't enable sliders for certain devices that don't have enough content
    // to warrant them.
    if (isDesktopUser) {
      selector += ':not(.disable-desktop)';
    } else if (isTabletUser) {
      selector += ':not(.disable-tablet)';
    } else if (isMobileUser) {
      selector += ':not(.disable-mobile)';
    }

    document.querySelectorAll(selector).forEach((node, index) => {
      node.classList.remove('disable-mobile', 'disable-tablet', 'disable-desktop');

      let statGal = node.closest('.stats-gallery');
      if (statGal) {
        statGal.classList.remove('stats-gallery');
        statGal.classList.add('stats-gallery-slider');
      }

      // Alignment of the arrows does not look right because of 100vw and scroll bar.
      let scrollbar = helpers.hasScroll(document.body);
      if (scrollbar) {
        let controls = node.parentElement.querySelector('.controls');
        if (controls) {
          controls.style.paddingRight = scrollbar + 'px';
        }
      }

      let navBullets = node.parentElement.querySelector('.bullets');
      let opt = options || {
        container: node,
        controlsContainer: node.parentElement.querySelector('.controls'),
        nav: navBullets ? true : false,
        navContainer: navBullets ? navBullets : false,
        loop: true,
        mouseDrag: true,
        preventScrollOnTouch: 'auto',
        autoplay: node.dataset.autoplay,
        speed: 300,
        gutter: node.dataset.gutter ? node.dataset.gutter : 0,
        responsive: {
          300: {
            edgePadding: statGal ? 15 : 0,
            fixedWidth: statGal ? 305 + 15 : false,
            items: node.dataset.mobileitems || 1
          },
          768: {
            edgePadding: 0,
            // Width of the stat + 10 px
            fixedWidth: statGal ? 305 + 10 : false,
            items: node.dataset.items || 1
          }
        }
      };

      opt.loop = true;
      opt.autoplayButtonOutput = false;

      this.sliders.push(tns(opt));

      if (opt.autoplay && navBullets) {
        let timeout = opt.autoplayTimeout || 5000;
        let controls = this.sliders[index].getInfo().navContainer;
        for (let i = 0; i < controls.children.length; i++) {
          controls.children[i].style.animationDuration = timeout + 'ms';
        }

        this.sliders[index].events.on(
          'indexChanged',
          ((index) => {
            setTimeout(this.sliders[index].play, 0);
          }).bind(this, index)
        );
      }

      if (opt.controlsContainer) {
        opt.controlsContainer.setAttribute('tabindex', -1);
        // Removing aria-label from controls element that is defaulted in by Tiny Slider
        opt.controlsContainer.removeAttribute('aria-label');
        let buttons = opt.controlsContainer.children;
        if (buttons.length !== 2) {
          // Something went wrong.
          return;
        }
        buttons[0].setAttribute('tabindex', 0);
        buttons[1].setAttribute('tabindex', 0);
      }
    });

    let headlineSliderControls = document.querySelector('.headline .controls');
    if (headlineSliderControls) {
      headlineSliderControls.removeAttribute('aria-label');
    }

    return this.sliders;
  }

  /**
   * Replace the current Cloudinary transformation with a new one.
   * @param {String} url The Cloudinary url. Must have a transformation applied.
   * @param {String} transformations The transformation str.
   * @return {String} The new URL.
   */
  alterCloudinaryImage(url, transformations) {
    // Replace /upload/w_600 from a url with a new transformation str.
    // eslint-disable-next-line no-useless-escape
    return url.replace(/\/(images|videos)\/[^\/]+/i, '/$1/' + transformations);
  }

  /**
   * Parse a video placeholder div into metadata.
   * @param {Element} el The placeholder div.
   * @return {Object} The video metadata.
   */
  parseVideoPlaceholder(el) {
    let sources = [],
      transcoding = el.classList.contains('transcoding'),
      data = Object.assign({}, el.dataset),
      captions = data.captions,
      poster = data.poster,
      ratio = data.ratio;

    Object.keys(data).forEach((key) => {
      let version = /^(\d+)p/.exec(key);
      if (version) {
        let height = parseInt(version[1], 10);
        sources.push({
          height: height,
          width: Math.round(height * SIXTEEN_NINE),
          label: key,
          url: data[key]
        });
      }
    });

    return {
      sources: sources,
      jwSources: this._getJwVideoSources(sources),
      poster: poster,
      ratio: ratio,
      captions: captions,
      transcoding: transcoding
    };
  }

  /**
   * Initialize the videos on the page for jwplayer.
   */
  _initVideos() {
    // TODO
    // Give mobile autoplay videos controls since they can't autoplay...
    // if (/mobile/.test(ResponsiveView.getLayout())) {
    //   $('video[autoplay]').attr('controls', 'true');
    // }
    //

    document.querySelectorAll('.video-placeholder').forEach((node) => {
      let parent = node.parentElement,
        play = parent.querySelector('.play'),
        posterContainer = parent.querySelector('.poster-container');

      // Campaign videos do not have this play button.
      if (play) {
        play.addEventListener('click', () => {
          this.renderVideo(node, true);
          // Small timeout to prevent content pop while hiding the poster.
          setTimeout(() => {
            if (posterContainer) {
              posterContainer.style.display = 'none';
            }
          }, 0);
        });
      }
    });
  }

  /**
   * Render a placeholder video tag.
   * @param {Element} el The placeholder div.
   * @param {Boolean} autoplay True to autoplay the video.
   * @return {jWplayer} A jwplayer object if this was a playable video.
   */
  renderVideo(el, autoplay = false) {
    const metadata = this.parseVideoPlaceholder(el);

    if (metadata.transcoding) {
      let transcodingOverlay = 'l_text:Arial_80:Transcoding,co_rgb:FFFFFF90';
      let transformations = 'w_1440';
      let screenSize = Math.max(screen.height, screen.width);

      if (screenSize < 1024) {
        // Smaller screens get smaller images and smaller transcoding text.
        transformations = 'w_375';
        transcodingOverlay = 'l_text:Arial_40:Transcoding,co_rgb:FFFFFF90';
      } else if (screenSize === 1024) {
        transformations = 'w_768';
      }

      if (metadata.ratio) {
        transformations += `,ar_${metadata.ratio}`;
      }

      if (window.devicePixelRatio && window.devicePixelRatio > 1) {
        transformations += ',dpr_2';
      }

      transformations += `/${transcodingOverlay}`;
      let transcodingPoster = this.alterCloudinaryImage(metadata.poster, transformations);
      el.innerHTML = '<img src="' + transcodingPoster + '" />';
    } else {
      let ratio = el.dataset.ratio,
        poster = metadata.poster,
        defaultSource = metadata.jwSources.filter((s) => s.default);

      if (defaultSource && defaultSource.length) {
        // Size poster according to default video size.
        switch (defaultSource[0].label) {
          case '720p':
            poster = poster.replace(/w_\d+/, 'w_1280');
            break;
          case '540p':
            poster = poster.replace(/w_\d+/, 'w_540');
            break;
          case '360p':
            poster = poster.replace(/w_\d+/, 'w_640');
            break;
        }
      }

      let videoConfig = {
        sources: metadata.jwSources,
        controls: true,
        image: poster,
        autostart: autoplay,
        sharing: {
          sites: ['facebook', 'twitter', 'linkedin']
        }
      };

      if (metadata.captions) {
        videoConfig.tracks = [
          {
            file: metadata.captions,
            // TODO will this be other languages?
            label: 'English',
            kind: 'captions',
            default: false
          }
        ];
      }

      if (ratio) {
        videoConfig.aspectratio = ratio;
      }

      // If this is an autoplay video (work mediaGrid), simply render an html5 video tag
      // and skip jwplayer.
      // TODO revisit autoplay
      // if (el.classList.contains('autoplay')) {
      //   let source = videoConfig[0];
      //   let sources = videoConfig.sources.filter((c) => c.default);
      //   if (sources.length) {
      //     source = sources[0];
      //   }
      //
      //   // Give mobile autoplay videos controls and a poster since they can't autoplay...
      //   if (/mobile/.test(ResponsiveView.getLayout())) {
      //     el.innerHTML = `<video controls autoplay muted loop src="${source.file}"
      //         poster="${poster}" />`;
      //   } else {
      //     el.innerHTML = `<video autoplay muted loop src="${source.file}" />`;
      //   }
      // } else {
      //   return this._setupJWPlayer(el, videoConfig);
      // }
      return this._setupJWPlayer(el, videoConfig);
    }
  }

  /**
   * Pause any active jwplayer videos.
   */
  pauseAllVideos() {
    for (let key in this.videoPlayers) {
      this.videoPlayers[key].player.pause();
    }
  }

  /**
   * Compute a JWPlayer sources param and choose the best default video size to play.
   * @param {Array} sources The available video sources.
   * @return {Array} The JWPlayer formatted sources param.
   */
  _getJwVideoSources(sources) {
    sources = sources || [];
    let initialSourceWidth = '',
      score = 99999999, // Something high because Number.MAX_SAFE_INTEGER don't work in IE
      screenSize = Math.max(screen.height, screen.width);

    // Determine which video size is closest to our screen size.
    sources.forEach((source) => {
      // Videos should be 16:9 widescreen, but just in case, use max of width and height.
      let diff = Math.abs(Math.max(source.width, source.height) - screenSize);
      if (diff < score) {
        score = diff;
        initialSourceWidth = source.width;
      }
    });

    return sources.map((item) => {
      let source = {
        file: item.url,
        label: item.height + 'p'
      };

      if (item.width === initialSourceWidth) {
        source.default = true;
      }

      return source;
    });
  }

  /**
   * Setup a jwplayer instance.
   * @param {Element} el The element to replace with the video.
   * @param {Object} options jwplayer options.
   * @return {JWPlayer} The video player instance.
   */
  _setupJWPlayer(el, options) {
    let name = el.dataset.name || options.name || window.document.title;

    let player = window.jwplayer(el).setup(options);
    this.videoPlayers[player.id] = {
      player: player,
      playbackTime: 0,
      timestamp: 0,
      name: name
    };

    player.on('play', () => {
      this.videoPlayers[player.id].timestamp = new Date().getTime();
      analytics.trackEvent('Video', {
        action: 'VideoPlay',
        video: this.videoPlayers[player.id].name
      });
    });

    player.on('pause', () => {
      this.trackVideoEngagement(player);
      this.videoPlayers[player.id].playbackTime +=
        new Date().getTime() - this.videoPlayers[player.id].timestamp;

      // Store the current playback time in sessionStorage. In some scenarios IOS devices
      // will not let us send this data to GA on page unload. If we fail to to execute a page
      // unload event, we'll fire these events to GA on next page load.
      let pendingAnalytics = window.sessionStorage.getItem(PENDING_VIDEO_ANALYTICS);
      if (pendingAnalytics) {
        pendingAnalytics = JSON.parse(pendingAnalytics);
      } else {
        pendingAnalytics = {};
      }
      let videoName = this.videoPlayers[player.id].name;
      pendingAnalytics[videoName] = this.videoPlayers[player.id].playbackTime;
      try {
        window.sessionStorage.setItem(PENDING_VIDEO_ANALYTICS, JSON.stringify(pendingAnalytics));
      } catch (e) {
        // Silence browsers who don't have session storage.
      }
    });

    player.on('complete', () => {
      this.trackVideoEngagement(player, 100);
      this.videoPlayers[player.id].playbackTime +=
        new Date().getTime() - this.videoPlayers[player.id].timestamp;
    });

    // Debounce the track fast forward method because IOS can fire it very quickly.
    let trackFastForward = debounce((videoName, percentComplete) => {
      analytics.trackEvent('Video', {
        action: 'Fast Forward (to percent)',
        video: videoName,
        value: percentComplete
      });
    }, 100);

    // If the user fast forwards, track it. For native IOS playback, do separately.
    // TODO what about android?
    if (/(iphone|ipad|ipod)/i.test(window.navigator.userAgent)) {
      player.on('ready', () => {
        let curTime = 0;
        player
          .getContainer()
          .querySelector('video')
          .on('timeupdate', function() {
            curTime = this.currentTime;
          });
        player
          .getContainer()
          .querySelector('video')
          .on('seeking', function() {
            if (this.currentTime > curTime) {
              let videoName = this.videoPlayers[player.id].name,
                percentComplete = Math.round((this.currentTime / this.duration) * 100);
              trackFastForward(videoName, percentComplete);
            }
          });
      });
    } else {
      player.on('seek', (data) => {
        if (data.offset > data.position) {
          let videoName = this.videoPlayers[player.id].name,
            percentComplete = Math.round((data.offset / player.getDuration()) * 100);
          trackFastForward(videoName, percentComplete);
        }
      });
    }

    return player;
  }

  /**
   * Track viewing statistics about a video.
   * @param {Object} player The jwplayer object.
   * @param {Number} percentComplete The percentage complete. Can be null to calculate it.
   * @param {Number} totalPlaybackTime Milliseconds of actual view time. Can be null.
   *     If set, no other events will be tracked.
   */
  trackVideoEngagement(player, percentComplete, totalPlaybackTime) {
    let videoName = this.videoPlayers[player.id].name;

    if (totalPlaybackTime) {
      analytics.trackEvent('Video', {
        action: 'Total View Time (seconds)',
        video: videoName,
        value: Math.round(totalPlaybackTime / 1000)
      });
      return;
    }

    if (!percentComplete) {
      percentComplete = Math.round((player.getPosition() / player.getDuration()) * 100);
    }
    analytics.trackEvent('Video', {
      action: 'Engagement (percentage watched)',
      video: videoName,
      value: percentComplete
    });

    // If we are within 10 seconds of the ending, track a watched event.
    if (player.getDuration() - player.getPosition() <= 10) {
      analytics.trackEvent('Video', { action: 'Watched', video: videoName });
    }
  }

  /**
   * Register page unload event to capture total time videos are watched stats.
   */
  _registerPageLeaveHandler() {
    let pageExitCalled = false;

    // Before leaving the page, try to track any video playback info we can.
    // Attempt multiple events for IOS devices which isn't standard.
    ['beforeunload', 'unload', 'pagehide'].forEach((eventName) => {
      window.addEventListener(eventName, () => {
        if (pageExitCalled) {
          return;
        }
        pageExitCalled = true;
        Object.keys(this.videoPlayers).forEach((key) => {
          let video = this.videoPlayers[key];
          if (video.player.getState() === 'playing') {
            this.trackVideoEngagement(video.player);
            video.playbackTime += new Date().getTime() - video.timestamp;
          }

          if (video.playbackTime) {
            this.trackVideoEngagement(video.player, null, video.playbackTime);
          }
        });
      });
    });
  }

  /**
   * HACK: MS Edge has a probem where any picture sources will always overlap
   * the underlying background-image with the img element, which is the basis
   * for our polyfill solution. Remove these in Edge to fix.
   */
  objectFitImagesEdge() {
    if (!SUPPORTS_PICTURE_EL || SUPPORTS_OBJECT_FIT) {
      return;
    }

    // $('picture img').each((i, el) => {
    //   const objectFitValue = getComputedStyle(el)['font-family'];
    //   if (HAS_POLYFILL_OBJECT_FIT.test(objectFitValue)) {
    //     $(el)
    //       .siblings()
    //       .remove();
    //   }
    // });
  }

  /**
   * Render a placeholder picture/img combination into the real deal.
   *
   * Turn:
   * <picture class="lazy">
   *    <source data-srcset="/path/">
   *    <img data-src="/path/">
   * </picture>
   *
   * Into:
   * <picture>
   *    <source srcset="/path/">
   *    <img src="/path/">
   * </picture>
   *
   * @param {Element} $picture The picture elemenet to render.
   * @return {Promise} a javascript promise when the image has loaded.
   */
  // eslint-disable-next-line no-unused-vars
  renderLazyPicture($picture) {
    // const $img = $picture.find('img'),
    //   imgSrc = $img.data('src'),
    //   deferred = $.Deferred();
    //
    // if ($picture.data('loaded')) {
    //   return deferred.resolve();
    // }
    //
    // $picture.removeClass('lazy');
    // $picture.find('source').each((i, el) => {
    //   const $el = $(el);
    //   $el.attr('srcset', $el.data('srcset'));
    // });
    //
    // $img.on('load', () => {
    //   $picture.data('loaded', true);
    //   deferred.resolve();
    // });
    //
    // $img.on('error', () => deferred.reject());
    // $img.attr('src', imgSrc);
    //
    // return deferred.promise();
  }
}

export default new AssetUtil();
