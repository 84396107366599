<template>
  <div
    class="modal"
    :class="{ active: isOpen, signup: !fullSize, 'full-size': fullSize }"
    v-click-outside="clickOutside"
    aria-modal="true"
  >
    <button class="close link" @click.prevent.stop="close" aria-label="Close" ref="close"></button>
    <div :class="fullSize ? 'content' : 'container'">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import ClickOutside from '../directives/click-outside.js';

export default {
  components: {},
  directives: { ClickOutside },

  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    fullSize: {
      type: Boolean,
      default: true
    },
    excludeClickOutside: {
      type: Boolean,
      default: true
    },
    closeOnClickOutside: {
      type: Boolean,
      default: false
    }
  },

  data: function() {
    return {
      scrollPosition: 0,
      preventClose: false,
      lastFocusedElement: null
    };
  },

  watch: {
    /**
     * Handle the scroll position based on the isOpen flag.
     * Stores the last focused element on open and restores focus on close.
     * Prevent scroll is only for full size modals.
     *
     * @param isOpen {Boolean} Is the modal open.
     */
    isOpen: function(isOpen) {
      if (isOpen) {
        this.preventClose = true;
        this.lastFocusedElement = document.activeElement;
        if (this.fullSize) {
          this.scrollPosition =
            window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
          document.body.classList.add('prevent-scroll');
          document.documentElement.classList.add('prevent-scroll');
        }
        // Small timeout to prevent immediate close for click outside.
        setTimeout(() => {
          this.preventClose = false;
        });
      } else if (this.fullSize) {
        document.body.classList.remove('prevent-scroll');
        document.documentElement.classList.remove('prevent-scroll');
        window.scrollTo(0, this.scrollPosition);
        this.lastFocusedElement.focus();
      }
    }
  },

  /**
   * Bind event on mounted.
   */
  mounted() {
    this.$el.addEventListener('keydown', this.tabHandler);
    document.body.addEventListener('keydown', this.escapeHandler);
  },

  computed: {
    /**
     * Exclude GDPR popups from click outside along with the modal
     * itself it the excludeClickOutside prop is true
     */
    clickOutside: function() {
      if (!this.closeOnClickOutside) {
        return { method: () => {}, exclude: [] };
      }

      let exclude = ['.optanon-alert-box-wrapper', '#optanon-popup-wrapper'];
      if (this.excludeClickOutside) {
        exclude.push('.modal');
      }

      return { method: this.close, exclude: exclude };
    },

    /**
     * Create a list of all focusable elements.
     */
    focusableEls: function() {
      let query =
        'a[href], area[href], input:not([disabled]), select:not([disabled]),' +
        ' textarea:not([disabled]), button:not([disabled]), [tabindex="0"]';
      return this.$el.querySelectorAll(query);
    }
  },

  methods: {
    /**
     * Emit the close modal event
     */
    close() {
      if (this.preventClose) {
        return;
      }
      this.$emit('close');
    },

    /**
     * Hijack the tab key the user can only focus elements inside the modal.
     * @param {Event} e The event.
     */
    tabHandler: function(e) {
      const KEY_TAB = 9;

      if (this.isOpen && e.keyCode === KEY_TAB) {
        let first = this.focusableEls[0],
          last = this.focusableEls[this.focusableEls.length - 1];

        if (this.focusableEls.length === 1) {
          e.preventDefault();
        } else if (e.shiftKey && this.focusableEls && last && document.activeElement === first) {
          e.preventDefault();
          last.focus();
        } else if (first && document.activeElement === last) {
          e.preventDefault();
          first.focus();
        }
      }
    },

    /**
     * Escape handler for the modals.
     * @param {Event} e The event.
     */
    escapeHandler: function(e) {
      const KEY_ESC = 27;

      if (this.isOpen && e.keyCode === KEY_ESC) {
        // OneTrust has an event listener for esc tha messes with out skip to content button.
        // Preventing propagation to avoid showing it.
        e.stopPropagation();
        this.close();
      }
    }
  },

  /**
   * Cleanup
   */
  beforeDestroy() {
    this.$el.removeEventListener('keydown', this.tabHandler);
    document.body.removeEventListener('keydown', this.escapeHandler);
  }
};
</script>
