import gdpr from '../util/gdpr';
import axios from 'axios';
import geolocation from '../util/user_location';
import { COUNTRY_CODES } from '../util/constants';

export default {
  data: function() {
    return {
      promptModal: {
        thankYouMessage: '',
        successMessage: '',
        errorMessage: '',
        loading: false,
        formType: 'download',
        defaultAction: '/actions/dot-com/api/get-download',
        action: '/actions/dot-com/api/get-download',
        storageKey: 'wtPromptForm',
        isOpen: false,
        id: null,
        source: null,
        buttonName: null,
        fileName: null,
        entryTitle: null
      },
      COUNTRY_CODES
    };
  },

  computed: {
    showPromptModalPhone() {
      return this.promptModal.formType === 'signup';
    }
  },

  methods: {
    /**
     * Open the prompt modal. if the user did not do so in the current session.
     * User the session data to submit the for if any.
     *
     * @param {Number} id The id of the download.
     * @param {String} buttonName The display name for the download button.
     * @param {String} fileName The name of the file, required for analytics.
     * @param {String} entryTitle The title of the entry, required for analytics.
     */
    openPromptModal(id, buttonName, fileName, entryTitle) {
      this.resetPromptForm();
      this.promptModal.id = id;
      this.promptModal.source = document.location.href;
      this.promptModal.buttonName = buttonName;
      this.promptModal.fileName = fileName;
      this.promptModal.entryTitle = entryTitle;
      this.promptModal.formType = 'download';

      try {
        if (sessionStorage.getItem(this.promptModal.storageKey)) {
          let downloadForm = JSON.parse(sessionStorage.getItem(this.promptModal.storageKey));

          Object.keys(downloadForm).forEach((key) => {
            this.$refs[key].value = downloadForm[key];
          });

          // Wait for the form to update before submitting.
          this.$nextTick(() => {
            // The submit will not trigger the Vue event, must do the tracking separately
            this.trackEvent('Download', {
              title: this.promptModal.entryTitle,
              file: this.promptModal.fileName
            });
            this.$refs['form'].submit();
          });

          return;
        }
      } catch (e) {
        // Ignore error
      }

      this.promptModal.isOpen = true;

      // Wait for the DOM to load before focus.
      this.$nextTick(() => {
        this.$refs['first-name'].focus();
      });
    },

    /**
     * Open the signup modal
     * @param {Number} id The id of the download.
     * @param {String} buttonName The display name for the download button.
     * @param {String} entryTitle The title of the entry, required for analytics.
     * @param {String} successMessage The success message.
     * @param {String} formType The form type, used to hide phone, will only show up for 'signup'.
     */
    openSignupModal(id, buttonName, entryTitle, successMessage, formType = 'signup') {
      this.resetPromptForm();
      this.promptModal.id = id;
      this.promptModal.source = document.location.href;
      this.promptModal.buttonName = buttonName;
      this.promptModal.entryTitle = entryTitle;
      this.promptModal.successMessage = successMessage;
      this.promptModal.formType = formType;
      this.promptModal.isOpen = true;

      if (this.showPromptModalPhone) {
        geolocation.location.then((userLocation) => {
          if (userLocation.data && userLocation.data.countryCode) {
            if (COUNTRY_CODES[userLocation.data.countryCode]) {
              this.$refs['countryCode'].value = userLocation.data.countryCode;
            }
          }
        });
      }

      // Wait for the DOM to load before focus.
      this.$nextTick(() => {
        this.$refs['first-name'].focus();
      });
    },

    /**
     * Resets form to its default state.
     */
    resetPromptForm() {
      // If we are submitting ti different set to download as the default.
      this.promptModal.action = this.promptModal.defaultAction;
      this.promptModal.thankYouMessage = '';
    },

    /**
     * Handle the form submit event.
     * @param e
     */
    onSubmit(e) {
      if (this.promptModal.formType === 'download') {
        this.saveUserPromptData();
      } else {
        e.preventDefault();
        this.submitSignupForm();
      }
    },

    /**
     * Save the user provided data to session storage so that we do not have to prompt
     * the user multiple times during a session.
     */
    saveUserPromptData() {
      this.trackEvent('Download', {
        title: this.promptModal.entryTitle,
        file: this.promptModal.fileName
      });
      this.promptModal.isOpen = false;

      let form = {
        'first-name': this.$refs['first-name'].value,
        'last-name': this.$refs['last-name'].value,
        email: this.$refs['email'].value,
        company: this.$refs['company'].value,
        'job-title': this.$refs['job-title'].value,
        industry: this.$refs['industry'].value
      };

      try {
        sessionStorage.setItem(this.promptModal.storageKey, JSON.stringify(form));
      } catch (e) {
        // Ignore error
      }
    },

    /**
     *
     */
    submitSignupForm() {
      let type = this.promptModal.formType === 'signup' ? 'Event' : 'Comms';
      this.trackEvent('Sign-up', { signup_type: type, title: this.promptModal.entryTitle });
      this.promptModal.loading = true;

      axios
        .post('/actions/dot-com/api/sign-up', {
          source: this.promptModal.source,
          id: this.promptModal.id,
          title: this.promptModal.entryTitle,
          firstName: this.$refs['first-name'].value,
          lastName: this.$refs['last-name'].value,
          email: this.$refs['email'].value,
          phone:
            this.showPromptModalPhone && this.$refs['phone'] && this.$refs['phone'].value
              ? '+' +
                COUNTRY_CODES[this.$refs['countryCode'].value] +
                ' ' +
                this.$refs['phone'].value
              : '',
          company: this.$refs['company'].value,
          jobTitle: this.$refs['job-title'].value,
          industry: this.$refs['industry'].value,
          campaignSource: this.$refs['campaign-source'].value,
          campaignMedium: this.$refs['campaign-medium'].value,
          campaignName: this.$refs['campaign-name'].value
        })
        .then(() => {
          this.promptModal.thankYouMessage = this.promptModal.successMessage;
        })
        .catch(() => {
          if (this.$refs['form']) {
            this.promptModal.thankYouMessage = this.$refs['form'].dataset.apiErrorMessage;
          } else {
            this.promptModal.thankYouMessage = 'Something went wrong, please try again later.';
          }
        })
        .then(() => {
          this.promptModal.loading = false;
        });
    },

    /**
     * Close the prompt modal.
     * @param e
     */
    closePromptModal(e) {
      this.promptModal.isOpen = false;
    }
  }
};
