class Body {
  static STAT_SELECTOR = 'section.stat-section';
  static TEXT_SELECTOR = 'section.text-section';

  constructor() {
    let el = document.querySelector('.body');
    if (!el) {
      return;
    }
    this.body = el;
  }
}

export default Body;
