/**
 * Google Translate the current page assuming page isn't natively available in that language.
 * @param {String} toLang
 * @returns
 */
export default function(toLang) {
  let hasRealTranslation = !!document.querySelector(`head > link[hreflang="${toLang}"]`);
  if (hasRealTranslation) {
    // Skip if we have it natively.
    return;
  }

  if (document.documentElement.getAttribute('lang') === toLang) {
    // Skip if for some reason the browser thinks we're already the desired language.
    return;
  }

  let $google = document.createElement('div');
  $google.setAttribute('id', 'google_translate_element');
  $google.style.display = 'none';
  document.body.append($google);

  // This cookie ensures Google Translate lib autoloads the language.
  document.cookie = `googtrans=/en/${toLang}; path=/; SameSite=Strict; ${
    window.location.protocol === 'https:' ? 'Secure' : ''
  } `;

  let observer = new MutationObserver(function(mutations) {
    let $select = $google.querySelector('select');
    if ($select) {
      observer.disconnect();
      $select.value = toLang;
      $select.dispatchEvent(new Event('change'));
    }
  });
  observer.observe($google, {
    attributes: false,
    childList: true,
    characterData: false,
    subtree: true
  });

  window.initGoogleTranslate = function() {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: 'en',
        includedLanguages: 'en,' + toLang
      },
      'google_translate_element'
    );
  };
  let el = document.createElement('script');
  el.src = 'https://translate.google.com/translate_a/element.js?cb=initGoogleTranslate';
  document.head.appendChild(el);
}
