<template>
  <nav class="mobile-nav">
    <div v-for="(slide, i) in slides" :key="slide.key" :class="i | slideClass" class="slide">
      <div v-if="slide.content && slide.content.parent" class="submenu breadcrumbs">
        <a
          v-for="(item, i) in breadcrumbs(slide.content)"
          :key="i"
          :href="item.url"
          data-action="Nav Menu"
          :aria-label="item.label"
          @click.prevent="prev(item.parent)"
        >
          <span v-if="item.parent" :aria-label="item.label" class="goto arrow prev"> </span>
          {{ item.label }}
        </a>
      </div>

      <div
        v-if="slide.content && slide.content.children"
        :class="{ left: slide.content.root }"
        class="submenu"
      >
        <a
          v-if="slide.content.parent && !slide.content.root && !slide.content.hideNav"
          :href="slide.content.url"
          data-action="Nav Menu"
          :aria-label="slide.content.label + ' ' + textHome"
        >
          {{ slide.content.label }} {{ textHome }}
        </a>
        <a
          v-for="(item, i) in slide.content.children"
          v-if="!item.hidden"
          :key="i"
          :href="item.url"
          data-action="Nav Menu"
          :aria-label="item.label"
          :class="{ active: item.active }"
          @click="next($event, item)"
        >
          {{ item.label }}
          <span v-if="item.children" :aria-label="item.label" class="goto arrow next"> </span>
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  props: {
    structure: {
      type: Object,
      default: () => {}
    },
    textHome: {
      type: String,
      default: 'Home'
    }
  },

  data: function() {
    let tree = {
      children: { ...this.structure.main },
      label: '',
      parent: null,
      root: true
    };

    let stack = [tree],
      active = tree,
      node;

    // Grabbing the active node and adding a reference to the parent node
    // so that we can access ancestors easily.
    while (stack.length > 0) {
      node = stack.pop();

      if (node.children) {
        // If the node is active.
        if (node.active || (!node.hideNav && window.location.href.includes(node.url))) {
          active = node;
        }

        Object.keys(node.children).forEach((key) => {
          node.children[key].parent = node;
          stack.push(node.children[key]);
        });
      }
    }

    return {
      tree,
      active,
      slides: [
        {
          key: 'previous',
          content: null
        },
        {
          key: 'current',
          content: null
        },
        {
          key: 'next',
          content: null
        }
      ]
    };
  },

  created() {
    this.const = {
      PREVIOUS_SLIDE: 0,
      CURRENT_SLIDE: 1,
      NEXT_SLIDE: 2
    };

    Object.freeze(this.const);
  },

  /**
   * Bind event on mounted.
   */
  mounted() {
    this.setContent(this.const.CURRENT_SLIDE, this.active);
    this.setContent(this.const.PREVIOUS_SLIDE, this.active.parent);
  },

  filters: {
    slideClass(index) {
      switch (index) {
        case 0:
          return 'previous';
        case 2:
          return 'next';
        default:
          return '';
      }
    }
  },

  methods: {
    /**
     * Go the the next slide and set the appropriate content.
     * @param {Event} e The event.
     * @param {Object} content The content.
     */
    next(e, content = null) {
      if (content && content.children) {
        e.preventDefault();
        this.setContent(this.const.NEXT_SLIDE, content);
        this.slides.push(this.slides.shift());
      }
    },

    /**
     * Go the the previous slide and set the appropriate content.
     * @param {Object | null} content
     */
    prev(content = null) {
      this.setContent(this.const.PREVIOUS_SLIDE, content);
      this.slides.unshift(this.slides.pop());
    },

    /**
     * Set the content for a slide/
     * @param {Number} slide
     * @param {Object | null} content
     */
    setContent(slide, content) {
      if (content) {
        this.slides[slide].content = content;
      }
    },

    /**
     *  Get the breadcrumbs for the given node.
     * @param {Object} node The node.
     * @return {Array}
     */
    breadcrumbs(node) {
      let bc = [];

      while (node.parent) {
        bc.push(node);
        node = node.parent;
      }

      return bc.reverse();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/mixins';
@import '../../scss/vars';

nav.mobile-nav {
  position: relative;
  width: 100%;
  // TODO: make this better,we should calculate the height based on the content.
  min-height: 740px;
  overflow: hidden;

  .slide {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    transition: left 400ms ease-in-out;

    &.next {
      left: 100%;
    }

    &.previous {
      left: -100%;
    }

    a {
      display: flex;
      align-content: center;
      font-family: $font-snowflake-sans;
      font-size: 1.325rem;
      line-height: 2.75rem;
      max-width: 600px;
      padding-bottom: 1rem;
    }

    .submenu {
      padding: calc(#{$column} / 4) $column;
      font-size: 1.12rem;

      &.left {
        padding-left: 0;
      }
    }

    .breadcrumbs {
      font-size: 1.37rem;
      padding-right: 0;
      padding-left: 0;

      a {
        justify-content: flex-start;
      }
    }

    .arrow {
      display: inline-block;
      cursor: pointer;
      margin-left: 0.25rem;

      &:before {
        @include arrow(0.6rem, 3px, -45deg);
      }

      &.prev {
        width: $column;

        &:before {
          transform: rotate(135deg);
          margin-left: calc(#{$column} / 4);
        }
      }
    }
  }
}
</style>
